import React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from "../../assets/images/resources/service-1-1.jpg";
import image2 from "../../assets/images/resources/service-1-2.jpg";
import image3 from "../../assets/images/resources/service-1-3.jpg";
import heart from "../../assets/images/shapes/heart-2-2.png";
const SERVICE_TWO_DATA = [
  {
    extraClassName: "background-primary",
    title: "BŁĄD 404",
  },
];
const ServiceTwo = () => {
  const sliderOptions = {
    spaceBetween: 0,
	  slidesPerView: 'auto',
    
  };
  return (
    <section className="service-two">
      <Container>
        <Swiper {...sliderOptions}>
          {SERVICE_TWO_DATA.map(
            ({ extraClassName, image, title, text, link }, index) => (
              <SwiperSlide key={`service-two-key-${index}`}>
                <div
                  className={`service-two__box ${extraClassName}`}
                  style={{ backgroundImage: `url(${image})` }}
                >
                  <div className="service-two__box-inner">
                    
                    <p>Strona której szukasz nie ma pod tym adrese.</p>
                    <h3>
                      {title}
                    </h3>
           
                  </div>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Container>
    </section>
  );
};

export default ServiceTwo;
